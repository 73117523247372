"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function selectedSetVal() {
    const selectElm = document.querySelector('[data-contactType-trigger]');
    const textareaElm = document.querySelector('[data-textarea-target]');
    selectElm.addEventListener('change', (e) => {
        if (e.target.value === '解約申請') {
            textareaElm.textContent = `注意事項　※必ずご確認ください※
指定住所以外への返却に関しては解約受領できませんのでご注意ください。

2年割なし
解約申請をいただいた翌月末が解約月となります。解約月の翌月5日までに端末の返却をお願いいたします。
なお、解約日は一律で月最終日とし、解約月は1ヶ月分のご利用料金をお支払いいただきます。
（例：4/20解約申請の場合 5/31付解約 6/5までに届くように返却）
※4月中の返却でも5月料金発生
※6/6以降の到着は6月料金発生

2年割あり
解約したい月に解約申請し、月末までに到着するように端末の返却をお願いいたします。
（例：4月中に解約申請 4/30までに到着するよう返却※月末が土日祝の場合でも同様）
※返却が遅れた場合は、翌月のご料金が発生します。
※複数台ご契約頂いているお客様は、全ての端末ご解約の場合、その旨を備考欄に記載のうえ申請をお願いいたします。
ご契約台数のうち特定の端末のみご解約の場合は、ご解約希望の端末のIMEI番号を備考欄に記載のうえ申請をお願いいたします。

※※※必ずご確認ください※※※
端末返却をもって解約となりますのでお忘れないようお願いいたします。
※返却が確認できない場合は引き続き利用とみなし、月額料金が発生致します
※返却期日などについてはよくある質問をご確認ください。

また、端末が故障している場合や紛失した場合は弁済費用が発生致します。
別途、下記窓口までご連絡ください。
その他の場合、お電話等でのご連絡は不要です。
===================================
■ 端末返却先
〒132-0024
東京都江戸川区一之江2-10-3　レブナイズモバイルWiFi機器返却窓口
※指定住所以外への返却に関しましては、一切の責任を負いかねますので予めご了承ください
■ 端末の故障、紛失の場合
https://rebnise-wifi.com/contact

■  返却物

・端末
・充電ケーブル
・ACアダプタ
・ユーザーマニュアル
・箱

※契約書面は必要ございません。
※返送時の送料はお客様負担となります。
※ユーザーマニュアル・箱は弁済費用請求対象外です
　上記紛失時は端末・充電ケーブル・ACアダプタをご返却ください
===================================
`;
        }
        else {
            textareaElm.textContent = '';
        }
    });
}
exports.default = selectedSetVal;
