"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const form_validate_1 = require("@wiz-creative/form-validate");
class FormScrieen {
    constructor(formInputs, formBtn) {
        this.formInputs = formInputs;
        this.formBtn = formBtn;
        this.formInputs = formInputs;
        this.formBtn = formBtn;
        this.formController = new FormController();
    }
    init() {
        this.event();
    }
    event() {
        console.log(this.formBtn);
        this.formController.bindEvents(this.formInputs, this.formBtn);
    }
}
class FormTrigger {
    constructor(validateResult) {
        this.validateResult = validateResult;
    }
    validate(elm) {
        let validateMethod = elm.getAttribute('data-validate-type');
        let labelName = elm.getAttribute('name');
        switch (elm.type) {
            case 'text':
            case 'select-one':
            case 'textarea':
                this.validateResult = form_validate_1.Validate.check(elm.value, validateMethod);
                break;
            default:
                if (elm.checked) {
                    this.validateResult = form_validate_1.Validate.check(elm.value, validateMethod);
                }
                break;
        }
        this.displayError(this.validateResult.isError, labelName, elm);
    }
    ;
    displayError(isError, labelName, elm) {
        let errorElm = elm.parentElement.parentNode.querySelector('[data-error-msg]');
        if (isError) {
            let errorMsg = form_validate_1.Validate.errorMsg(this.validateResult.key, labelName, this.validateResult.params);
            elm.classList.add('is-error');
            errorElm.innerHTML = errorMsg;
        }
        else {
            elm.classList.remove('is-error');
            errorElm.innerHTML = '';
        }
    }
}
class FormController {
    constructor() {
        this.check = new FormTrigger();
    }
    event(elms, eventKey) {
        elms.forEach((elm) => {
            elm.addEventListener(eventKey, (e) => {
                this.check.validate(e.target);
            });
        });
    }
    checkValue(inputElms, focus) {
        for (let i = 0; i < inputElms.length; i++) {
            const elm = inputElms[i];
            this.check.validate(elm);
            if (focus) {
                if (!elm.value) {
                    elm.focus();
                    break;
                }
            }
        }
    }
    getCheckBoxVal(elms) {
        let checkVal;
        elms.forEach((elm) => {
            if (elm.checked) {
                checkVal = elm.value;
            }
        });
        return checkVal;
    }
    bindEvents(inputElms, formBtn) {
        if (!formBtn)
            return;
        this.event(inputElms, 'keyup');
        this.event(inputElms, 'change');
        formBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.checkValue(inputElms, null);
            this.checkValue(inputElms, true);
            const errorInputs = document.querySelectorAll('.is-error[data-validate-type]');
            const formBody = document.querySelector('[data-target-formBody]');
            if (errorInputs.length == 0) {
                console.log(this.check.fromBody);
                formBody.submit();
            }
        });
    }
}
;
function fromRelation() {
    const formScrieen = new FormScrieen(document.querySelectorAll(`[data-validate-type]`), document.querySelector(`[data-target-btn]`));
    formScrieen.init();
}
exports.default = fromRelation;
