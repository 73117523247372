"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function planTab() {
    const target1 = document.querySelector('[data-planTab-target1]');
    const trigger1 = document.querySelector('[data-planTab-trigger1]');
    const target2 = document.querySelector('[data-planTab-target2]');
    const trigger2 = document.querySelector('[data-planTab-trigger2]');
    if (!trigger1)
        return;
    trigger1.addEventListener('click', () => {
        target1.classList.remove('is-show');
        target2.classList.add('is-show');
    });
    trigger2.addEventListener('click', () => {
        target1.classList.add('is-show');
        target2.classList.remove('is-show');
    });
}
exports.default = planTab;
