"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function accordion(triggers) {
    triggers.forEach((trigger) => {
        let ans = trigger.nextElementSibling;
        trigger.addEventListener('click', function () {
            ans.classList.toggle('is-open');
            trigger.classList.toggle('is-active');
        });
    });
}
exports.default = accordion;
