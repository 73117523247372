"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function humbarger() {
    const humbargerClose = document.querySelector('[data-humbarger-close]');
    const humbargerMenu = document.querySelector('[data-humbarger-menu]');
    const humbargerOpen = document.querySelector('[data-humbarger-open]');
    const humbargerLogo = document.querySelector('[data-humbarger-Logo]');
    const humbargerBtn = document.querySelector('[data-humbarger-btn]');
    const humbargerNavBtns = document.querySelectorAll('.humbargerClose');
    const HTML = document.querySelector('html');
    if (!humbargerOpen)
        return;
    humbargerOpen.addEventListener('click', () => {
        console.log('click');
        humbargerMenu.classList.add('is-show');
        humbargerLogo.classList.add('is-show');
        humbargerClose.classList.add('is-show');
        humbargerBtn.classList.add('is-show');
        HTML.classList.add('is-hidden');
    });
    humbargerClose.addEventListener('click', () => {
        humbargerMenu.classList.remove('is-show');
        humbargerLogo.classList.remove('is-show');
        humbargerClose.classList.remove('is-show');
        humbargerBtn.classList.remove('is-show');
        HTML.classList.remove('is-hidden');
    });
    humbargerNavBtns.forEach((humbargerNavBtn) => {
        humbargerNavBtn.addEventListener('click', () => {
            humbargerMenu.classList.remove('is-show');
            humbargerLogo.classList.remove('is-show');
            humbargerClose.classList.remove('is-show');
            humbargerBtn.classList.remove('is-show');
            HTML.classList.remove('is-hidden');
        });
    });
}
exports.default = humbarger;
